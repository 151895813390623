<template>
	<div class="my-history">
		<!-- <div class="remove" >

		</div> -->
		<el-popconfirm
			title="确定删除清除全部观看记录吗？"
			@onConfirm="deleteAllHistory()"
			class="remove"
			v-show="moiveList.length || moiveList2.lengthv || Object.keys(moiveList3).length"
		>
			<span
				slot="reference"
				style="width: 140px;height: 50px;display: flex;justify-content: center;align-items: center;"
				>清除全部记录</span
			>
		</el-popconfirm>

		<div class="history-list" >
			<div class="day-title" v-if="moiveList.length > 0">今天</div>
			<div class="day-moive" v-if="moiveList.length > 0">
				<div class="day-moive-item" v-for="(item, index) in moiveList" :key="index">
					<div class="moive-item-left" @click="goDetail(item.videoId)">
						<!-- <img :src="getImg(item.bigPoster, 320, 180)" alt="" /> -->
						<lazy-pic :array="[item.bigPoster, '320', '180']" :type="'bigPoster'" :isWrapper="'a'" />
					</div>
					<div class="moive-item-right">
						<div class="moive-item-right-title" @click="goDetail(item.videoId)">
							{{ item.name }}

							<span v-show="item.seasonName">{{ item.seasonName }}</span>
							<span v-show="item.episode">第{{ item.episode }}集</span>
						</div>
						<div class="moive-item-right-category">
							{{ typeObject[item.type] }}
						</div>
						<div class="moive-item-right-time">
							已观看
							<span>{{ item.percent }}%</span>
						</div>
						<div class="moive-item-right-date">
							<span>今天 {{ item.createAt.substring(11, 16) }}</span>
							<el-popconfirm title="确定移除此观看记录吗？" @onConfirm="deleteHistory(item)">
								<el-button type="text" style="color: rgba(255, 52, 101, 1)" slot="reference"
									>移除</el-button
								>
							</el-popconfirm>
						</div>
					</div>
				</div>
			</div>
			<div class="day-title" v-if="moiveList2.length > 0">昨天</div>
			<div class="day-moive" v-if="moiveList2.length > 0">
				<div class="day-moive-item" v-for="(item, index) in moiveList2" :key="index">
					<div class="moive-item-left" @click="goDetail(item.videoId)">
						<!-- <img :src="getImg(item.bigPoster, 320, 180)" alt="" /> -->
						<lazy-pic :array="[item.bigPoster, '320', '180']" :type="'bigPoster'" :isWrapper="'a'" />
					</div>
					<div class="moive-item-right">
						<div class="moive-item-right-title" @click="goDetail(item.videoId)">
							{{ item.name }}
							<span v-show="item.seasonName">{{ item.seasonName }}</span>
							<span v-show="item.episode">第{{ item.episode }}集</span>
						</div>
						<div class="moive-item-right-category">
							{{ typeObject[item.type] }}
						</div>
						<div class="moive-item-right-time">
							已观看
							<span>{{ item.percent }}%</span>
						</div>
						<div class="moive-item-right-date">
							<span>昨天 {{ item.createAt.substring(11, 16) }}</span>
							<el-popconfirm title="确定移除此观看记录吗？" @onConfirm="deleteHistory(item)">
								<el-button type="text" style="color: rgba(255, 52, 101, 1)" slot="reference"
									>移除</el-button
								>
							</el-popconfirm>
						</div>
					</div>
				</div>
			</div>

			<div v-if="Object.keys(moiveList3).length>0" v-for="(value, key, index) in moiveList3" >
				<div class="day-title">{{ key }}</div>
				<div class="day-moive">
					<div class="day-moive-item" v-for="(item, index) in value" :key="index">
						<div class="moive-item-left" @click="goDetail(item.videoId)">
							 <img :src="getImg(item.bigPoster, 320, 180)" alt="" />
<!--							{{item}}-->
<!--							<lazy-pic :array="[item.bigPoster, '320', '180']" :type="'bigPoster'" :isWrapper="'a'" />-->
						</div>
						<div class="moive-item-right">
							<div class="moive-item-right-title" @click="goDetail(item.videoId)">
								{{ item.name }}
								<span v-show="item.seasonName">{{ item.seasonName }}</span>
								<span v-show="item.episode"> 第{{ item.episode }}集</span>
							</div>
							<div class="moive-item-right-category">
								{{ typeObject[item.type] }}
							</div>
							<div class="moive-item-right-time">
								已观看
								<span>{{ item.percent }}%</span>
							</div>
							<div class="moive-item-right-date">
								<span
									>{{ new Date(item.createAt).getFullYear() }}年{{
										new Date(item.createAt).getMonth() + 1 > 9
											? new Date(item.createAt).getMonth() + 1
											: "0" + (new Date(item.createAt).getMonth() + 1)
									}}月{{
										new Date(item.createAt).getDate() + 1 > 9
											? new Date(item.createAt).getDate() + 1
											: "0" + (new Date(item.createAt).getDate() + 1)
									}}日 {{ " " + item.createAt.substring(11, 16) }}</span
								>
								<el-popconfirm title="确定移除此观看记录吗？" @onConfirm="deleteHistory(item)">
									<el-button type="text" style="color: rgba(255, 52, 101, 1)" slot="reference"
										>移除</el-button
									>
								</el-popconfirm>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="no-work" v-if="moiveList.length === 0 && moiveList2.length === 0 && Object.keys(moiveList3).length === 0">
			<img :src="emptyIcon" alt="" />
			<span>暂无内容</span>
		</div>
		<!-- <page-select
			v-show="moiveList.length || moiveList2.lengthv || Object.keys(moiveList3).length"
			:pagination="pagination"
			@pageChange="pageChange"
		></page-select> -->
		<div class="bodymovinanim" v-show="loadingShow">
			<div>
				<img :src="loadingIcon" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import pageSelect from "./page-select"
export default {
	name: "my-history",
	data() {
		return {
			moiveList: [],
			wantIcon: require("@assets/explore/want.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			loadingIcon: require("@assets/loading.gif"),
			moiveList2: [],
			moiveList3: {},
			loadingShow: false,

			typeObject: {
				movie: "电影",
				tv: "剧集",
			},

			pagination: {
				currentPage: 1,
				pageSize: 0,
				numFound: 0,
				totalPage: 0,
			},
		}
	},
	methods: {
		pageChange(e) {
			this.pagination.currentPage = e
			this.init()
		},

		deleteAllHistory() {
			this.loadingShow = false
			console.log('...this.moiveList, ...this.moiveList2, ...Object.values(this.moiveList3)', ...this.moiveList.map(a=>a.id), ...this.moiveList2, ...Object.values(this.moiveList3))
			this.api.removeHistory({
				ids: [...this.moiveList, ...this.moiveList2, ...Object.values(this.moiveList3)].map((i) => i.id),
			}).then(res=> {
				if (res.code === 1) {
					this.moiveList = []
          this.moiveList2 = []
          this.moiveList3 = []
					this.pagination.currentPage = 1
					this.init()
					this.$message.success("清除成功")
				} else {
					this.$message.error(result.msg)
				}
			})
		},
		deleteHistory(item) {
			console.log(item)
			console.log(this.moiveList3)

			this.api.removeHistory({ ids: [item.id] }).then(res=>{
				if (res.code === 1) {
					this.pagination.currentPage = 1
					this.moiveList = this.moiveList.filter(a => a.id !== item.id)
					this.moiveList2 = this.moiveList2.filter(a => a.id !== item.id)

					for (let moiveList3Key in this.moiveList3) {
						this.moiveList3[moiveList3Key] = this.moiveList3[moiveList3Key].filter(a => a.id !== item.id)
						if(this.moiveList3[moiveList3Key].length === 0) {
							this.moiveList3[moiveList3Key] = null
						}
					}
					this.init()

					this.$message.success("移除成功")
				} else {
					this.$message.error(result.msg)
				}
			})

		},
		init() {
      console.log('init')
			this.api.getWatchList({
				currentPage: this.pagination.currentPage,
			}).then(result => {
				console.log(result)

				this.pagination = result.data.pagination
				for (let item of result.data.list) {
					const { createAt } = item
					console.log(createAt)
					item.percent = `${(Number((item.second / item.totalTime).toFixed(2)) * 100).toFixed()}`
					item.time = ``

					if (parseInt(item.second / 60)) {
						if (parseInt(item.second / 60) >= 10) item.time += parseInt(item.second / 60)
						else item.time += `0${parseInt(item.second / 60)}`
					} else {
						item.time += "00"
					}
					item.time += ":"
					let second = item.second - parseInt(item.second / 60) * 60
					if (second) {
						if (second >= 10) item.time += second
						else item.time += `0${second}`
					} else item.time += "00"

					if (this.isToday(createAt)) {
						if (!this.moiveList.filter(a => a.id === item.id).length > 0) {
							this.moiveList.push(item)
						}
					} else if (this.isYestday(new Date(createAt))) {
						if (!this.moiveList2.filter(a => a.id === item.id).length > 0) {
							this.moiveList2.push(item)
						}
					} else {
						const date = item.createAt.substring(0, 10)
						if (!this.moiveList3[date]) {
							this.moiveList3[date] = []
						}

						this.moiveList3[date].push(item)

					}
				}
				// 解决由于moiveList3 动态data无法自动刷新
				this.$forceUpdate()

				// this.moiveList = result.data.list
				this.loadingShow = false
				console.log(this.moiveList, this.moiveList2, this.moiveList3)

				console.log('Object.keys(moiveList3).length', Object.keys(this.moiveList3).length)
			})

		},
		isToday(date) {
			//Code goes here.
			let d = new Date(date.toString().replace(/-/g, "/"))
			let todaysDate = new Date()
			if (d.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
				return true
			} else {
				return false
			}
		},
		isYestday(theDate) {
			let date = new Date() //当前时间

			let today = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() //今天凌晨

			let yestday = new Date(today - 24 * 3600 * 1000).getTime()

			return theDate.getTime() < today && yestday <= theDate.getTime()
		},
		getScroll() {
			let _this = this
			document.querySelector(".el-main").addEventListener(
				"scroll",
				function(e) {
					// console.log(e)
					if (_this.pagination.totalPage !== _this.pagination.currentPage) {
						_this.loadingShow = true
					}
					const { scrollHeight, scrollTop, offsetHeight } = e.target
					// console.log(parseInt(scrollTop + offsetHeight), scrollHeight);
					if (parseInt(scrollTop + offsetHeight) === scrollHeight) {
						console.log("到底了")

						setTimeout(() => {
							console.log(_this.pagination)
							if (_this.pagination.totalPage === _this.pagination.currentPage) return
							_this.pagination.currentPage += 1
							_this.debounce(_this.pageChange(_this.pagination.currentPage), 1000)
						}, 500)
					}
				},
				true
			)
		},
		debounce(fn, delay) {
			let timer = null
			return function() {
				if (timer) {
					clearInterval(timer)
					timer = setTimeout(fn, delay)
				} else {
					timer = setTimeout(fn, delay)
				}
			}
		},
	},
	mounted() {
		this.init()
		this.getScroll()

	},
	activated() {
		// this.init()  // 会导致数据重复
	},
	components: {
		pageSelect,
	},
}
</script>

<style lang="scss" scoped>
.no-work {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 200px;
	img {
		width: 300px;
		height: 210px;
	}
	span {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 21px;
		color: #797a8e;
		margin-top: 20px;
		user-select: none;
	}
}
.my-history {
	position: relative;
	margin-top: 40px;
	min-height: 100px;
	.remove {
		width: 140px;
		height: 50px;
		background: rgba(255, 52, 101, 0.1);
		border-radius: 10px;
		position: absolute;
		right: 0;
		top: 0;
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 21px;
		color: #ff3465;
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		cursor: pointer;
	}
	.history-list {
		.day-title {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 600;
			line-height: 25px;
			color: #ff3465;
		}
		.day-moive {
			border-left: 2px solid rgba(121, 122, 142, 0.5);
			padding: 20px 28px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			margin-left: 10px;
			margin-top: 10px;
			margin-bottom: 10px;
			.day-moive-item {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 50%;
				margin-bottom: 20px;

				.moive-item-left {
					width: 48%;
					border-radius: 10px;
					overflow: hidden;
					cursor: pointer;
					img {
						width: 100%;
						height: auto;
						display: block;
					}
				}
				.moive-item-right {
					margin-left: 20px;
					.moive-item-right-title {
						font-size: 22px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 30px;
						color: #b6b7c3;
						width: 230px;
						cursor: pointer;
					}
					.moive-item-right-category {
						width: 46px;
						height: 24px;
						background: rgba(255, 52, 101, 0.05);

						font-size: 13px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 18px;
						color: #ff3465;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 5px;
						margin-top: 10px;
					}
					.moive-item-right-time {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 24px;
						color: #797a8e;
						margin-top: 30px;
					}
					.moive-item-right-date {
						display: flex;
						justify-content: space-between;
						align-items: center;
						span {
							font-size: 13px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 22px;
							color: rgba(121, 122, 142, 0.5);
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1680px) {
	.my-history {
		.history-list {
			.day-moive {
				.day-moive-item {
					.moive-item-right {
						.moive-item-right-title {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}
</style>
